<script setup lang="ts">
import { type TriggerForm, Channel, DurationType } from '@critical-dates'
// import { FormKitMessages } from '@formkit/vue'
// import { getNode } from '@formkit/core'

const props = withDefaults(
  defineProps<{
    formValue: TriggerForm | undefined
    disabled?: boolean
    eventName?: string
  }>(),
  {
    formValue: () => ({}) as TriggerForm,
    disabled: false,
    eventName: undefined,
  },
)

function getMaxUnit(index: number): number {
  if (props.formValue?.alertTypes?.[index]?.durationType === DurationType.DAYS)
    return 365
  if (props.formValue?.alertTypes?.[index]?.durationType === DurationType.WEEKS)
    return 52
  if (
    props.formValue?.alertTypes?.[index]?.durationType === DurationType.MONTHS
  )
    return 36
  // if (props.formValue?.alertTypes?.[index]?.durationType === DurationType.YEARS) return 5
  return 0
}
</script>

<template>
  <div>
    <small
      :class="[
        'formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white',
        disabled && 'opacity-40',
      ]"
    >
      Notifications
      <span v-if="eventName" class="text-gray-450 text-sm">
        (prior to {{ eventName }})
      </span>
    </small>
    <FormKit
      id="reminders"
      v-slot="{ items, node, value: alertTypes }"
      type="list"
      :value="[{}]"
      dynamic
      name="alertTypes"
      label="Notifications"
      validation="required|length:1"
    >
      <!-- TODO: Prevent to delete the first one -->
      <FormKit
        v-for="(item, index) in items"
        :key="item"
        type="group"
        :index="index"
      >
        <div class="mb-2 flex items-center gap-4">
          <div class="flex gap-4">
            <FormKit
              type="listbox"
              name="channel"
              :options="[
                { label: 'Email', value: Channel.EMAIL },
                // { label: 'SMS', value: Channel.SMS },
                // { label: 'Email and SMS', value: Channel.EMAIL_AND_SMS },
              ]"
              :value="Channel.EMAIL"
              outer-class="w-28 md:w-36"
              validation="required"
            />
            <FormKit
              :id="'reminders-frequency' + index"
              type="number"
              name="value"
              :value="3"
              :min="1"
              :max="getMaxUnit(index)"
              :validation="`required|max:${getMaxUnit(index)}|min:1`"
              outer-class="w-16 md:w-24"
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              data-1p-ignore
            />

            <FormKit
              type="listbox"
              name="durationType"
              outer-class="w-28 md:w-36"
              :options="[
                {
                  label:
                    formValue?.alertTypes?.[index].value == 1 ? 'Day' : 'Days',
                  value: DurationType.DAYS,
                },
                {
                  label:
                    formValue?.alertTypes?.[index].value == 1
                      ? 'Week'
                      : 'Weeks',
                  value: DurationType.WEEKS,
                },
                {
                  label:
                    formValue?.alertTypes?.[index].value == 1
                      ? 'Month'
                      : 'Months',
                  value: DurationType.MONTHS,
                },
              ]"
              :value="DurationType.DAYS"
              validation="required"
            />
          </div>

          <Button
            v-if="index !== 0"
            color="transparentSecondary"
            icon="close"
            class="px-2 py-2"
            @click.prevent="
              () => node.input(alertTypes?.filter((_, i) => i !== index))
            "
          />
        </div>

        <!-- <FormKitMessages :node="getNode('value')" /> -->
      </FormKit>

      <div>
        <Button
          v-if="alertTypes && alertTypes?.length < 5"
          size="sm"
          color="secondary"
          icon="plus"
          @click.prevent="
            () =>
              node.input(
                alertTypes?.concat({
                  durationType: DurationType.DAYS,
                  value: 5,
                  channel: Channel.EMAIL,
                }),
              )
          "
        >
          Add more dates
        </Button>
      </div>
    </FormKit>
  </div>
</template>
